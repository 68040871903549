import React from 'react';
import {InputGroup, Input, Box } from '@chakra-ui/react';
import { FormControl, FormErrorMessage, FormLabel } from '@chakra-ui/form-control';
import { Icon } from './Icon';
import { DANGER, DARK, GRAY, GRAY_VARIANT, LIGHT, SUCCESS_DARKER } from '../appTheme';
import {Text} from "@chakra-ui/layout";

export const FormInput = React.forwardRef(({
  name,
  isRequired, 
  label, 
  errorMessage, 
  iconName, 
  isDirectionColumn, 
  marginBottom, 
  labelWidth, 
  formgroupstatus, 
  placeholder, 
  type,
  id,
  whiteSpace,
  inputvalue,
  onChange,
  onBlur,
  autoFocus,
  step,
  isDisabled,
  hint
 }, ref) => {

    return (
      <FormControl 
        display="flex" 
        marginBottom={marginBottom ? marginBottom : "1em"} 
        flexDirection={{base: "column", md: isDirectionColumn ? "column" : "row"}}  
        gap={{ base: ".25em" }} 
        isDisabled={formgroupstatus === "disabled"}
        width="100%"
        isInvalid={formgroupstatus === "invalid"}
        fontSize="1em"
      >
        {label &&
          <FormLabel
            display="flex"
            flexBasis={labelWidth ? labelWidth : "40%"}
            gap=".5rem"
            flexShrink={0}
            fontSize="1em"
            optionalIndicator={isRequired ? <Box as="span" color={DANGER} mt=".25em" ml="-.25rem">*</Box> : null}
          >
            {iconName &&
              <Icon
                iconName={iconName}
                margin=".5em 0 0 0"
                iconWidth="1.75em"
                iconColor={GRAY}
              />
            }
            <Box
              as="span"
              color={GRAY}
              fontWeight="bold"
              marginTop=".66666em"
              whiteSpace={whiteSpace}
              fontSize="1em"
            >
              {label}
            </Box>
          </FormLabel>
        }
        <InputGroup fontSize="1em">
           <Box width="100%">
            <Box
                as="label"
                position="relative"
                display="block"
                opacity={formgroupstatus === 'disabled' ? "0.4" : null}
                cursor={formgroupstatus === 'disabled' ? "not-allowed" : null}
              >
                <Input
                  name={name}
                  id={id && id}
                  ref={ref}
                  onChange={onChange}
                  onBlur={onBlur}
                  bg={LIGHT}
                  type={type ? type : "text"}
                  borderWidth="1px"
                  placeholder={placeholder}
                  _placeholder={{ opacity: .4 }}
                  fontSize="1em"
                  height="2.5em"
                  color={DARK}
                  defaultValue={inputvalue}
                  borderColor={formgroupstatus === "success" ? SUCCESS_DARKER : GRAY_VARIANT}
                  step={step}
                  autoFocus={autoFocus}
                  isDisabled={isDisabled}
                />
              </Box>
             {hint &&
               <Text
                 fontSize=".8em"
                 lineHeight="1.2em"
                 textAlign="justify"
               >
                 {hint}
               </Text>
             }
            <FormErrorMessage fontSize="1em">
              {errorMessage ? errorMessage : "Champ invalide"}
            </FormErrorMessage>
           </Box>
        </InputGroup>
      </FormControl>
    )
  }
);